import { Autocomplete, Button, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import Api from '../../Services/Api';
import * as Sentry from "@sentry/browser";
import Properties from './Properties';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { useTranslation } from 'react-i18next';
import CompanyAutocomplete from './CompanyAutocomplete.js';
const Company = ({company, selectCompany, setCompany, loaded}) => {
    const [createCompany, setCreateCompany] = React.useState(company.create);
    const [newCompany, setNewCompany] = React.useState(company);
    const [options, setOptions] = React.useState([]);
    const [defaultValue, setDefaultValue] = React.useState(undefined);
    const { t } = useTranslation();
    useState(() => {
        if(company.options != undefined) {
            setOptions(company.options)
            setDefaultValue(company.options.filter((option) => option.id == company.id)[0])            
        } else if(company.options && company.options.length > 0) {
            setDefaultValue(company.options[0]);
        }
        loaded();
    }, [])

    return (
        <Stack spacing={2} style={{width: "100%"}}>
            {createCompany &&
            <Stack spacing={2} fullWidth style={{ width: "100%" }}>
                <TextField fullWidth id="standard-basic" label={t('companyName')} defaultValue={newCompany.name} variant="standard" onChange={(event) => {
                    var newData = {name: event.target.value, domain: newCompany.domain, create: true, properties: newCompany.properties, fixed: true};
                    setNewCompany(newData);
                    setCompany(newData);
                }} />
                <TextField fullWidth id="standard-basic" label={t('companyDomain')} variant="standard" defaultValue={newCompany.domain} onChange={(event) => {
                    var newData = {name: newCompany.name, domain: event.target.value, create: true, properties: newCompany.properties, fixed: true};
                    setNewCompany(newData);
                    setCompany(newData);
                }} />
                {newCompany.properties != undefined && <Properties properties={newCompany.properties} setProperties={(properties) => {
                    var newData = { ...newCompany, properties: properties, fixed: true };
                    setNewCompany(newData);
                    setCompany(newData);
                }} />}
            </Stack>
            }

            {!createCompany &&
            <CompanyAutocomplete opts={options} company={newCompany} setCompany={company => { setCompany(company); setNewCompany(company) }} />
            }   
            <Button variant="contained" color="primary" onClick={() => { setCreateCompany(!createCompany) }}>
                <ChangeCircleIcon />
                {createCompany && t('selectCompany')}
                {!createCompany && t('createCompany')}
            </Button>
        </Stack>
    );
}

export default Company;