import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
const Task = ({task, setTask, loaded}) => {
    const { t } = useTranslation();
    const [newTask, setNewTask] = React.useState({
        subject: task.subject || '',
        priority: task.priority || 'MEDIUM',
        dueDate: task.dueDate || '',
        hs_task_type: task.hs_task_type || '',
        description: task.description || ''
    });
    loaded();
    return (
        <Stack spacing={2} fullWidth style={{ width: "100%" }}>
            <TextField
                fullWidth
                id="standard-basic"
                label={t('subject')}
                variant="standard"
                multiline
                defaultValue={task.subject}
                onChange={(event) => {
                    const newData = { subject: event.target.value, priority: newTask.priority, dueDate: newTask.dueDate, hs_task_type: newTask.hs_task_type, description: newTask.description, fixed: true };
                    setNewTask(newData);
                    setTask(newData);
                }}
            />

            <FormControl fullWidth>
                <InputLabel id="priority-label">Prioridad</InputLabel>
                <Select
                    labelId="priority-label"
                    id="priority-select"
                    label="Prioridad"
                    defaultValue={task.priority}
                    onChange={(event) => {
                        const newData = { subject: newTask.subject, priority: event.target.value, dueDate: newTask.dueDate, hs_task_type: newTask.hs_task_type, description: newTask.description, fixed: true };
                        setNewTask(newData);
                        setTask(newData);
                    }}
                >
                    <MenuItem value="LOW">{t('low')}</MenuItem>
                    <MenuItem value="MEDIUM">{t('medium')}</MenuItem>
                    <MenuItem value="HIGH">{t('high')}</MenuItem>
                </Select>
            </FormControl>

            <TextField
                fullWidth
                id="dueDate"
                label={t('dueDate')}
                type="number"
                variant="standard"
                defaultValue={task.dueDate}
                onChange={(event) => {
                    const newData = { subject: newTask.subject, priority: newTask.priority, dueDate: event.target.value, hs_task_type: newTask.hs_task_type, description: newTask.description, fixed: true };
                    setNewTask(newData);
                    setTask(newData);
                }}
            />

            <FormControl fullWidth>
                <InputLabel id="hs-task-type-label">{t('hsTaskType')}</InputLabel>
                <Select
                    labelId="hs-task-type-label"
                    id="hs-task-type-select"
                    label={t('hsTaskType')}
                    defaultValue={task.hs_task_type}
                    onChange={(event) => {
                        const newData = { subject: newTask.subject, priority: newTask.priority, dueDate: newTask.dueDate, hs_task_type: event.target.value, description: newTask.description, fixed: true };
                        setNewTask(newData);
                        setTask(newData);
                    }}
                >
                    <MenuItem value="CALL">{t('call')}</MenuItem>
                    <MenuItem value="EMAIL">{t('email')}</MenuItem>
                    <MenuItem value="TODO">{t('todo')}</MenuItem>
                </Select>
            </FormControl>

            <TextField
                fullWidth
                id="description"
                label={t('description')}
                variant="standard"
                multiline
                defaultValue={task.description}
                onChange={(event) => {
                    const newData = { subject: newTask.subject, priority: newTask.priority, dueDate: newTask.dueDate, hs_task_type: newTask.hs_task_type, description: event.target.value, fixed: true };
                    setNewTask(newData);
                    setTask(newData);
                }}
            />
        </Stack>
    );
}

export default Task;