import { Alert, Button, Card, CircularProgress, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import logo from '../images/logo.png';
import wave from '../images/wave.svg';
import AccountComponent from './AccountComponent';
import { Navigate, useNavigate } from 'react-router-dom';
import Api from '../Services/Api';
import Loading from '../components/Loading';
import { useTranslation } from 'react-i18next';
const Account = () => {
    const { t } = useTranslation();
    const [gmailSuccess, setGmailSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null)
    const [gmailDisconnectSuccess, setGmailDisconnectSuccess] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const gmailSuccess = urlParams.get('gmailSuccess');
        setGmailSuccess(gmailSuccess);
        Api.getUserData().then((response) => {
            setUser(response)
            setLoading(false)
        }).catch((error) => {
            navigate("/")
        });
    }, []);

    async function disconnectGmail() {
        Api.disconnectGmail().then(() => {
            setUser({...user, gmail: false});
            setGmailDisconnectSuccess(true);
        });
    }
    
    return (
        <div style={{flexDirection: "row", display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center", height: "100vh" }}>
            <Loading loading={loading} />
            <Card style={{ padding: 20, margin: "auto", backgroundColor: "#FFFFFF40" }}>
                {!loading && <AccountComponent user={user} disconnectGmail={disconnectGmail}/>}
            </Card>
            {gmailSuccess && <Alert style={{position: "absolute", top: 0, left: 0, right: 0, margin: 10}} severity="success">{t('gmailConnected')}</Alert>}
            {gmailDisconnectSuccess && <Alert style={{position: "absolute", top: 0, left: 0, right: 0, margin: 10}} severity="error">{t('gmailDisconnected')}</Alert>}
        </div>
        
    );
};

export default Account;