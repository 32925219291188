import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import React, { useState, useRef } from 'react';
import Api from '../../Services/Api';
import * as Sentry from "@sentry/browser";
import { useTranslation } from 'react-i18next';
const ContactAutocomplete = ({opts, contact, setContact}) => {
    const [options, setOptions] = React.useState([]);
    const [after, setAfter] = React.useState(undefined);
    const previousController = useRef();
    const { t } = useTranslation();
    useState(() => {
        if (opts != undefined && opts.length > 0) {
            setOptions(opts)
        }
    }, []);

    const onInputChangeContact = (event, value, reason) => {
        if (value && reason === "input") {
            if (previousController.current) {
                previousController.current.abort();
              }
              const controller = new AbortController();
              const signal = controller.signal;
              previousController.current = controller;
            
            Api.fetchContacts({firstName: value, after: after, signal: signal}).then((result) => {
                if(result.options.length > 0) {
                    setOptions(result.options)
                }
                setAfter(result.after)
            }).catch((error) => {
                setAfter(undefined)
                console.log(error)
            })
        }
    };

    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            style={{ width: "100%" }}
            defaultValue={options.filter((option) => option.id === contact.id)[0]}
            onInputChange={onInputChangeContact}
            onChange={(event, newValue) => {
                if (newValue != null && newValue.id != null) {
                    setContact({ id: newValue.id, firstname: newValue.firstname, lastname: newValue.lastname, email: newValue.email, create: false, options: options })
                }
            }}
            options={options.sort((a, b) => {
                if (a != null && a.firstname != null && b.firstname != null) return -b.firstname.localeCompare(a.firstname)
            })}
            groupBy={(option) => {
                if (option && option.firstname && option.firstname.length > 0) {
                    return option.firstname.charAt(0).toUpperCase();
                }
                return '';
            }
            }
            getOptionLabel={(option) => { 
                if (option != null) {
                    return `${option.firstname} ${option.lastname} ${option.email ? " - " + option.email : ""}`
                }
                return "";
            }}
            renderInput={(params) => <TextField {...params} label={t('selectContact')} />}
            renderOption={(props, option) => (
                <Stack spacing={1} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Typography variant="subtitle1" style={{width: "100%", textAlign: "left"}}>{option.firstname + " " + option.lastname}</Typography>
                    {option.email && <Typography variant="caption" style={{width: "100%", textAlign: "left"}} color="text.secondary">{option.email}</Typography>}
                </Stack>
            )}
        />
    );
}

export default ContactAutocomplete;