import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';

const Loading = ({loading}) => {

    return (
        <>
        {loading && <div style={{height: "100vh", width: "100vw", position: "absolute", top: "0", left: "0", right: "0", bottom: "0", backdropFilter: "blur(10px)", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "1000"}}>
                <CircularProgress style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}/>
            </div>}
        </>
        
    )
}

export default Loading;