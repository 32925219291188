import React, { useEffect } from 'react';
import ContactAutocomplete from './ContactAutocomplete';
import { Alert, Button, Stack, TextField } from '@mui/material';
import Properties from './Properties';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { useTranslation } from 'react-i18next';

const Contact = ({contact, setContact, userAlreadyExists, loaded}) => {
    const [newContact, setNewContact] = React.useState(contact);
    const [createContact, setCreateContact] = React.useState(contact.create);
    loaded();
    const { t } = useTranslation();
    return (
        <Stack spacing={2} style={{width: "100%"}}>
            
            {createContact && <Stack spacing={2} fullWidth style={{ width: "100%" }}>
            {userAlreadyExists &&
                <Alert severity="error" >{t('userAlreadyExists')}</Alert>
            }
                <TextField fullWidth id="standard-basic" label={t('name')} variant="standard"
                    defaultValue={contact.firstname}
                    onChange={(event) => {
                        var newData = { firstname: event.target.value, lastname: newContact.lastname, email: newContact.email, phone_number: newContact.phone_number, create: true, options: [], properties: newContact.properties, fixed: true };
                        setNewContact(newData);
                        setContact(newData);
                }} />
                <TextField fullWidth id="standard-basic" label={t('lastName')} variant="standard"
                    defaultValue={contact.lastname}
                    onChange={(event) => {
                        var newData = { firstname: newContact.firstname, lastname: event.target.value, email: newContact.email, phone_number: newContact.phone_number, create: true, options: [], properties: newContact.properties, fixed: true };
                        setNewContact(newData);
                        setContact(newData);
                    }} />
                <TextField fullWidth id="standard-basic" label={t('email')} variant="standard"
                    defaultValue={contact.email}
                    type="email"
                    onChange={(event) => {
                        var newData = { firstname: newContact.firstname, lastname: newContact.lastname, email: event.target.value, phone_number: newContact.phone_number, create: true, options: [], properties: newContact.properties, fixed: true };
                        setNewContact(newData);
                        setContact(newData);
                    }} />
                <TextField fullWidth id="standard-basic" label={t('phoneNumber')} variant="standard"
                    defaultValue={contact.phone_number}
                    onChange={(event) => {
                        var newData = { firstname: newContact.firstname, lastname: newContact.lastname, email: newContact.email, phone_number: event.target.value, create: true, options: [], properties: newContact.properties, fixed: true };
                        setNewContact(newData);
                        setContact(newData);
                    }} />

                    {newContact.properties != undefined && <Properties properties={newContact.properties} setProperties={
                        (properties) => {
                            var newData = { ...newContact, properties: properties, fixed: true };
                            setNewContact(newData);
                            setContact(newData); 
                        }

                    } />}
            </Stack>}
           {!createContact && <ContactAutocomplete opts={contact.options} contact={contact} setContact={setContact} />}
           <Button variant="contained" color="primary" onClick={() => { 
                setContact({ ...contact, create: !createContact })
                setCreateContact(!createContact)
            }}>
                <ChangeCircleIcon />
                {createContact && t('selectContact')}
                {!createContact && t('createContact')}
            </Button>
        </Stack>
    );
}

export default Contact;