import React, { Component } from 'react';
import { CircularProgress, Typography, Box, Fade, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import animationProcessing from '../images/animation_processing.json';
import styles from '../App.module.css';

const processing = {
  loop: true,
  autoplay: true,
  animationData: animationProcessing,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

class ProcessingComponent extends Component {
  render() {
    const { processingText } = this.props;
    return (
      <Fade in={true}>
        <Stack spacing={2} style={{display: "flex",
      flexDirection: "column",
      position: "sticky",
      justifyContent: "center"}}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "-65px" }}>
            <Typography variant="h5" gutterBottom align="center" style={{ color: "white" }}>{processingText}</Typography>
            <Lottie
              options={processing}
              height={"125px"}
              width={"125px"}
              style={{ marginTop: "100px" }}
            />
          </div>
        </Stack>
      </Fade>
    );
  }
}

ProcessingComponent.propTypes = {
  processingText: PropTypes.string.isRequired,
};

export default ProcessingComponent;
