import React, { useState } from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ContactAutocomplete from './ContactAutocomplete';
import CompanyAutocomplete from './CompanyAutocomplete';
import DealAutocomplete from './DealAutocomplete';
import { useTranslation } from 'react-i18next';

function Note({ note, setNote, loaded }) {
    const { t } = useTranslation();
    const [newNote, setNewNote] = useState({
        note: note.note || '',
        contacts: note.contacts || [],
        companies: note.companies || [],
        deals: note.deals || [],
        contactsOptions: note.contactsOptions || [],
        companiesOptions: note.companiesOptions || [],
        dealsOptions: note.dealsOptions || []
    });
    if(newNote.contacts != null && newNote.contactsOptions == null){
        newNote.contactsOptions = [];
        newNote.contacts.forEach((contact) => {
            newNote.contactsOptions.push(contact);
        });
    }
    if(newNote.companies != null && newNote.companiesOptions == null){
        newNote.companiesOptions = [];
        newNote.companies.forEach((company) => {
            newNote.companiesOptions.push(company);
        });
    }
    if(newNote.deals != null && newNote.dealsOptions == null){
        newNote.dealsOptions = [];
        newNote.deals.forEach((deal) => {
            newNote.dealsOptions.push(deal);
        });
    }
    setNote(newNote);
    loaded();
    
    return (
        <Stack spacing={2} fullWidth style={{ width: "100%" }}>
            <TextField
                fullWidth
                id="content"
                label={t('note')}
                variant="standard"
                multiline
                defaultValue={note.note}
                onChange={(event) => {
                    var newData = { note: event.target.value, contacts: newNote.contacts, companies: newNote.companies, deals: newNote.deals, contactsOptions: newNote.contactsOptions, companiesOptions: newNote.companiesOptions, dealsOptions: newNote.dealsOptions, fixed: true }
                    setNewNote(newData);
                    setNote(newData);
                }}
            />

            <Stack spacing={2} fullWidth style={{ width: "100%", alignItems: "center" }} direction="row">
                <Typography variant="h6">{t('contacts')}</Typography>
                <AddCircleIcon onClick={() => {
                    note.contacts.push({});
                    var newData = { note: newNote.note, contacts: note.contacts, companies: newNote.companies, deals: newNote.deals, contactsOptions: newNote.contactsOptions, companiesOptions: newNote.companiesOptions, dealsOptions: newNote.dealsOptions, fixed: true }
                    setNewNote(newData);
                    setNote(newData);
                }
                } />
            </Stack>
            {note.contacts != null && note.contacts.map((contact, index) => {
                return (
                    <Stack spacing={2} fullWidth style={{ width: "100%", alignItems: "center" }} direction="row">
                        <ContactAutocomplete opts={note.contactsOptions} contact={contact}
                            setContact={(contact) => {
                                note.contacts[index] = contact;
                                var newData = { note: newNote.note, contacts: note.contacts, companies: newNote.companies, deals: newNote.deals, contactsOptions: newNote.contactsOptions, companiesOptions: newNote.companiesOptions, dealsOptions: newNote.dealsOptions}
                                setNewNote(newData);
                                setNote(newData);
                            }} />
                        <RemoveCircleIcon onClick={() => {
                            note.contacts.splice(index, 1);
                            var newData = { sender: newNote.sender, contacts: note.contact, subject: newNote.subject, message: newNote.message, contactsOptions: newNote.contactsOptions, companiesOptions: newNote.companiesOptions, dealsOptions: newNote.dealsOptions}
                            setNewNote(newData);
                            setNote(newData);
                        }} />
                    </Stack>
                )
            })
            }
            

            <Stack spacing={2} fullWidth style={{ width: "100%", alignItems: "center" }} direction="row">
                <Typography variant="h6">{t('companies')}</Typography>
                <AddCircleIcon onClick={() => {
                    note.companies.push({});
                    var newData = { note: newNote.note, contacts: newNote.contacts, companies: note.companies, deals: newNote.deals, contactsOptions: newNote.contactsOptions, companiesOptions: newNote.companiesOptions, dealsOptions: newNote.dealsOptions}
                    setNewNote(newData);
                    setNote(newData);
                }} />
            </Stack>
            {note.companies != null && note.companies.map((company, index) => {
                return (
                    <Stack spacing={2} fullWidth style={{ width: "100%", alignItems: "center" }} direction="row">
                        <CompanyAutocomplete opts={note.companiesOptions} company={company}
                            setCompany={(company) => {
                                note.companies[index] = company;
                                var newData = { note: newNote.note, contacts: newNote.contacts, companies: note.companies, deals: newNote.deals, contactsOptions: newNote.contactsOptions, companiesOptions: newNote.companiesOptions, dealsOptions: newNote.dealsOptions}
                                setNewNote(newData);
                                setNote(newData);
                            }} />
                        <RemoveCircleIcon onClick={() => {
                            note.companies.splice(index, 1);
                            var newData = { note: newNote.note, contacts: newNote.contacts, companies: note.companies, deals: newNote.deals, contactsOptions: newNote.contactsOptions, companiesOptions: newNote.companiesOptions, dealsOptions: newNote.dealsOptions}
                            setNewNote(newData);
                            setNote(newData);
                        }} />
                    </Stack>
                )
            })
            }

            <Stack spacing={2} fullWidth style={{ width: "100%", alignItems: "center" }} direction="row">
                <Typography variant="h6">{t('deals')}</Typography>
                <AddCircleIcon onClick={() => {
                    note.deals.push({});
                    var newData = { note: newNote.note, contacts: newNote.contacts, companies: newNote.companies, deals: note.deals, contactsOptions: newNote.contactsOptions, companiesOptions: newNote.companiesOptions, dealsOptions: newNote.dealsOptions}
                    setNewNote(newData);
                    setNote(newData);
                }} />
            </Stack>
            {note.deals != null && note.deals.map((deal, index) => {
                return (
                    <Stack spacing={2} fullWidth style={{ width: "100%", alignItems: "center" }} direction="row">
                        <DealAutocomplete opts={note.dealsOptions} deal={deal}
                            setDeal={(deal) => {
                                note.deals[index] = deal;
                                var newData = { note: newNote.note, contacts: newNote.contacts, companies: newNote.companies, deals: note.deals, contactsOptions: newNote.contactsOptions, companiesOptions: newNote.companiesOptions, dealsOptions: newNote.dealsOptions}
                                setNewNote(newData);
                                setNote(newData);
                            }} />
                        <RemoveCircleIcon onClick={() => {
                            note.deals.splice(index, 1);
                            var newData = { note: newNote.note, contacts: newNote.contacts, companies: newNote.companies, deals: note.deals, contactsOptions: newNote.contactsOptions, companiesOptions: newNote.companiesOptions, dealsOptions: newNote.dealsOptions}
                            setNewNote(newData);
                            setNote(newData);
                        }} />
                    </Stack>
                )
            })
            }

        </Stack>
    );
}

export default Note;