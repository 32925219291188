import styles from '../App.module.css';
import { useState, useEffect, useRef } from 'react'
import { Box, Dialog, DialogActions, DialogContent, Button, CircularProgress, Grid, Typography, Alert } from '@mui/material';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import api from '../Services/Api';
import Company from './components/Company';
import Deal from './components/Deal';
import Task from './components/Task';
import Contact from './components/Contact';
import Meeting from './components/Meeting';
import Pipeline from './components/Pipeline';
import PipelineStages from './components/PipelineStages';
import * as Sentry from "@sentry/browser";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Mail from './components/Mail';
import Note from './components/Notes';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import validate from 'node-cron/src/pattern-validation';
import Call from './components/Call';
import ProcessingComponent from '../designsystem/ProcessingComponent';
import { getLanguage } from '../Services/i18n';
import { useTranslation } from 'react-i18next';
function Validate() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const { setHide, user } = useOutletContext();
    const { t } = useTranslation();
    const text = state ? state.text : "";

    //Errors handling
    const [error, setError] = useState(false)
    const [errorTitle, setErrorTitle] = useState("Error desconocido")
    const [errorType, setErrorType] = useState(null)
    const [userAlreadyExists, setUserAlreadyExists] = useState(false)
    const [loading, setLoading] = useState(false)
    const [processingData, setProcessingData] = useState(true)
    const [titleProcessing, setTitleProcessing] = useState("Identificando acciones")
    const [open, setOpen] = useState(false);
    //Success handling
    const [success, setSuccess] = useState(false)
    const [textProcessed, setTextProcessed] = useState(false)
    const [validateOptions, setValidateOptions] = useState([])
    const [nextDisabled, setNextDisabled] = useState(false)
    const [routeBack, setRouteBack] = useState("/app")
    var json = useRef({})
    var index = useRef(0)
    var socket = api.socket;

    useEffect(() => {
        setHide(true)
        if (!state || !state.text) {
            setHide(false)
            navigate("/app");
        }

        if (state.json) {
            json.current = state.json
        }

        if (state.routeBack) {
            setRouteBack(state.routeBack)
        }

        if (!textProcessed) {
            setTextProcessed(true)
            sendText(text);
        }
        socket.on('closeText', disconnect);
        socket.on('progress', progressUpdate);
        socket.on('finished', textCallback);
        socket.on('actions', actionsCallback);
        socket.on('actionsUpdate', actionsUpdateCallback);
        socket.on('error', errorCallback);
        return () => {
            socket.off('connect', () => { });
            socket.off('closeText', disconnect);
            socket.off('progress', progressUpdate);
            socket.off('finished', textCallback);
            socket.off('actions', actionsCallback);
            socket.off('actionsUpdate', actionsUpdateCallback);
            socket.off('error', errorCallback);
        };
    }, [validateOptions]);

    function disconnect() {
        console.log('disconnected');
        socket.off('connect', () => { });
        socket.off('closeText', disconnect);
        socket.off('progress', progressUpdate);
        socket.off('finished', textCallback);
        socket.off('actions', actionsCallback);
        socket.off('actionsUpdate', actionsUpdateCallback);
        socket.off('error', errorCallback);
        socket.disconnect();
        socket.close();
    }
    function progressUpdate(progress) {
        console.log(progress);
        setTitleProcessing(progress);
        // updateProgress(progress);
    }

    function textCallback(response) {
        console.log('textCallback');
    }

    function errorCallback(error) {
        Sentry.captureException(error);
        setError(true)
        setLoading(true)
        setProcessingData(false)
        if (error && error.text) {
            setHide(false)
            navigate("/app", { state: { text: error.text } })
        }
        /*if (error.code == "ERR_BAD_REQUEST" && error.status != 401) {
            api.processError(error.response.data, reject);
        } else {
        }*/
    }

    function actionsCallback(actions) {
        /*if (actions == undefined || Object.keys(actions).length <= 0) {
            Sentry.captureException(new Error("No actions found"));
            setError(true)
            setLoading(true)
            setProcessing(false)
            return
        } else {
            
        }*/
        if (json.current && Object.keys(json.current).length > 0) {
            json.current = { ...json.current, ...actions };
        } else {
            json.current = actions;
        }
        setProcessingData(true)
        processSuccess(json.current);
    }

    function actionsUpdateCallback(action) {
        console.log("Action update", action)
        var objIndex = validateOptions.findIndex(option => option.type == action.type);
        if (objIndex == -1) {
            console.log("Action not found", action.type)
            validateOptions.push({ type: action.type, dataProcessed: true })
            setValidateOptions([...validateOptions])
            actionsUpdateCallback(action)
            return
        }

        switch (action.type) {
            case api.ERROR.DEAL:
                json.current.deal = action.data
                break;
            case api.ERROR.COMPANY:
                json.current.company = action.data
                break;
            case api.ERROR.CONTACT:
                json.current.contact = action.data
                break;
            case api.ERROR.TASK:
                json.current.task = action.data
                break;
            case api.ERROR.MEETING:
                json.current.meeting = action.data
                processMeeting();
                break;
            case api.ERROR.MAIL:
                json.current.mail = action.data
                processMail();
                break;
            case api.ERROR.NOTE:
                json.current.note = action.data
                processNote();
                break;
            case api.ERROR.CALL:
                json.current.call = action.data
                processCall();
                break;
            default:
                console.log("Unknown action type:", action.type);
                break;
        }

        if (objIndex == index.current) {
            setLoading(false)
            setProcessingData(false)
        }
        validateOptions[objIndex].dataProcessed = true
        setValidateOptions([...validateOptions])
    }

    const handleClose = () => {
        setOpen(false);
    };

    const sendJson = () => {
        clearBeforeSend()
        setProcessingData(true)
        setTitleProcessing(t('sendingData'))
        disconnect()
        api.CreateDealFromJson(json.current).then((result) => {
            console.log("Result", result)
            var message = undefined
            if (result.errors && result.errors.length > 0) {
                message = t('notObjectProcessed')
                result.errors.forEach(error => {
                    var errorText = ""
                    switch (error) {
                        case api.ERROR.COMPANY:
                            errorText = t('company')
                            break;
                        case api.ERROR.CONTACT:
                            errorText = t('contact')
                            break;
                        case api.ERROR.DEAL:
                            errorText = t('deal')
                            break;
                        case api.ERROR.TASK:
                            errorText = t('task')
                            break;
                        case api.ERROR.MEETING:
                            errorText = t('meeting')
                            break;
                        case api.ERROR.MAIL:
                            errorText = t('mail')
                            break;
                        case api.ERROR.NOTE:
                            errorText = t('note')
                            break;
                        case api.ERROR.CALL:
                            errorText = t('call')
                            break;
                        case api.ERROR.PIPELINE:
                            errorText = t('pipeline')
                            break;
                        case api.ERROR.PIPELINE_STAGES:
                            errorText = t('pipelineStages')
                            break;
                        default:
                            errorText = t('other')
                            break;
                    }
                    message += errorText + "\n"
                })
            }
            setProcessingData(false)
            setLoading(false)
            setSuccess(true)
            socket.off('closeText', disconnect);
            socket.off('progress', progressUpdate);
            socket.off('finished', textCallback);
            socket.off('actions', actionsCallback);
            socket.off('actionsUpdate', actionsUpdateCallback);
            socket.off('error', errorCallback);
            setHide(false)
            navigate(routeBack, { state: { ok: true, fail: result.errors && result.errors.length > 0, message: message } })
        }).catch((error) => {
            setProcessingData(false)
            index.current = 0
            Sentry.captureException(error);
            setErrorType(error.error)
            var options = [{ type: error.error, dataProcessed: true }]
            setValidateOptions(options)
            json.current = error.originalJson
            switch (error.error) {
                case api.ERROR.COMPANY:
                    json.current.company = {}
                    json.current.company.options = error.options
                    break;
                case api.ERROR.CONTACT:
                    json.current.contact = {}
                    json.current.contact.options = error.options
                    break;
                case api.ERROR.DEAL:
                    options.push({ type: api.ERROR.PIPELINE, dataProcessed: true })
                    options.push({ type: api.ERROR.PIPELINE_STAGES, dataProcessed: true })
                default:
                    break;
            }
            setValidateOptions(options)
            processError(options)
        })
    }

    const clearBeforeSend = () => {
        setErrorTitle("")
        index.current = 0
        setValidateOptions([])
    }

    const sendText = (text) => {
        setProcessingData(true)
        socket = api.socket;
        if (socket.connected) {
            socket.emit('text', { data: { text: text, json: json.current, language: getLanguage() } });
        } else {
            socket.connect();
            socket.on('connect', () => {
                console.log('connected');
            });
            socket.emit('text', { data: { text: text, json: json.current, language: getLanguage() } });
        }
    }

    const processSuccess = (json) => {
        setLoading(true)
        setProcessingData(false)
        var options = []
        if (json.deal) {
            options.push({ type: api.ERROR.DEAL, dataProcessed: false })
            options.push({ type: api.ERROR.PIPELINE, dataProcessed: true })
            options.push({ type: api.ERROR.PIPELINE_STAGES, dataProcessed: true })
        }

        if (json.contact && Object.keys(json.contact).length == 0) {
            options.push({ type: api.ERROR.CONTACT, dataProcessed: false })
        }
        if (json.company && Object.keys(json.company).length == 0) {
            options.push({ type: api.ERROR.COMPANY, dataProcessed: false })
        }
        if (json.task) {
            options.push({ type: api.ERROR.TASK, dataProcessed: false })
        }
        if (json.meeting) {
            options.push({ type: api.ERROR.MEETING, dataProcessed: false })
        }
        if (json.note) {
            options.push({ type: api.ERROR.NOTE, dataProcessed: false })
        }
        if (json.mail) {
            options.push({ type: api.ERROR.MAIL, dataProcessed: false })
        }
        if (json.call) {
            options.push({ type: api.ERROR.CALL, dataProcessed: false })
        }
        setValidateOptions(options)
        processError(options)
    }

    const processError = (options) => {
        let error = options[index.current]
        console.log("Processing error", index.current, error, json.current)
        setProcessingData(false)
        if (error && error.type) {
            setErrorType(error.type)
            switch (error.type) {
                case api.ERROR.PIPELINE:
                    setErrorTitle(t('selectPipeline'))
                    break;
                case api.ERROR.PIPELINE_STAGES:
                    setErrorTitle(t('selectPipelineStage'))
                    break;
                case api.ERROR.COMPANY:
                    setErrorTitle(t('selectCompany'))
                    break;
                case api.ERROR.CONTACT:
                    setErrorTitle(t('selectContact'))
                    break;
                case api.ERROR.TASK:
                    setErrorTitle(t('selectTask'))
                    break;
                case api.ERROR.MEETING:
                    setErrorTitle(t('selectMeeting'))
                    processMeeting();
                    break;
                case api.ERROR.DEAL:
                    setErrorTitle(t('selectDeal'))
                    break;
                case api.ERROR.MAIL:
                    setErrorTitle(t('selectMail'))
                    processMail();
                    break;
                case api.ERROR.NOTE:
                    setErrorTitle(t('selectNote'))
                    processNote();
                    break;
                case api.ERROR.CALL:
                    setErrorTitle(t('selectCall'))
                    processCall();
                    break;
                default:
                    Sentry.captureException(error);
                    if (error.status == 401) {
                        setHide(false)
                        navigate("/")
                    } else {
                        setHide(false)
                        navigate("/app", { state: { ok: false, fail: true } })
                    }
            }
        } else {
            console.log("Error type is undefined or null")
            errorCallback(error)
        }
    }


    const createContact = () => {
        if (json.current.contact.create) {
            api.createContact(json.current.contact).then((result) => {
                json.current.contactOld = json.current.contact
                json.current.contact = {
                    email: result.email,
                    firstname: result.firstname,
                    lastname: result.lastname,
                    id: result.id,
                    create: false,
                    options: [result],
                    fixed: json.current.contact.fixed || false
                }
                continueOrFinish()
            }).catch((error) => {
                setLoading(false)
                setUserAlreadyExists(true)
                Sentry.captureException(error);
            })
        } else {
            continueOrFinish(false)
        }
    }

    const continueOrFinish = (loading = true) => {
        if (index.current + 1 < validateOptions.length) {
            index.current = index.current + 1
            setLoading(loading)
            processError(validateOptions)
        } else {
            finishFlow()
        }
    }

    const createCompany = () => {
        if (json.current.company.create) {
            api.createCompany(json.current.company).then((result) => {
                json.current.companyOld = json.current.company
                json.current.company = {
                    id: result.id,
                    name: result.name,
                    domain: result.domain,
                    create: false,
                    options: [result],
                    fixed: json.current.company.fixed || false
                }
                continueOrFinish()
            }).catch((error) => {
                setLoading(false)
                Sentry.captureException(error);
            })
        } else {
            continueOrFinish(false)
        }
    }

    const processMeeting = () => {
        if (json.current && json.current.meeting && Object.keys(json.current.meeting).length > 0 && json.current.contact) {
            var contact = json.current.contact
            if (json.current.meeting.attendees == undefined || json.current.meeting.attendees.length <= 0) {
                json.current.meeting.attendees = []
                json.current.meeting.attendees.push(contact)
            } else {
                json.current.meeting.attendees[0] = contact
            }
            if (json.current.meeting.options == undefined || json.current.meeting.options.length <= 0) {
                json.current.meeting.options = []
            }
            json.current.meeting.options.push(contact)
        }
    }

    const processMail = () => {
        if (json.current && json.current.mail && Object.keys(json.current.mail).length > 0 && json.current.contact) {
            var contact = json.current.contact
            if (json.current.mail.recipient == undefined || json.current.mail.recipient.length <= 0) {
                json.current.mail.recipient = []
                json.current.mail.recipient.push(contact)
            } else {
                json.current.mail.recipient[0] = contact
            }
            if (json.current.mail.options == undefined || json.current.mail.options.length <= 0) {
                json.current.mail.options = []
            }
            json.current.mail.options.push(contact)
        }
    }

    const processNote = () => {
        if (json.current == undefined || json.current.note == undefined) {
            return
        }
        if (json.current && json.current.note && Object.keys(json.current.note).length > 0 && json.current.note.contacts && json.current.contact) {
            var contact = json.current.contact
            if (json.current.note.contacts == undefined || json.current.note.contacts.length <= 0) {
                json.current.note.contacts = []
                json.current.note.contacts.push(contact)
            } else {
                json.current.note.contacts[0] = contact
            }
            if (json.current.note.contactsOptions == undefined || json.current.note.contactsOptions.length <= 0) {
                json.current.note.contactsOptions = []
            }
            json.current.note.contactsOptions.push(contact)
        }
        if (Object.keys(json.current.note).length > 0 && json.current.note.companies && json.current.company) {
            var company = json.current.company
            if (json.current.note.companies == undefined || json.current.note.companies.length <= 0) {
                json.current.note.companies = []
                json.current.note.companies.push(company)
            } else {
                json.current.note.companies[0] = company
            }
            if (json.current.note.companiesOptions == undefined || json.current.note.companiesOptions.length <= 0) {
                json.current.note.companiesOptions = []
            }
            json.current.note.companiesOptions.push(company)
        }

    }

    const processCall = () => {
        if (json.current && json.current.call && Object.keys(json.current.call).length > 0 && json.current.contact) {
            json.current.call.contact = json.current.contact
        }
    }

    const finishFlow = () => {
        sendJson();
        setProcessingData(true);
        setTitleProcessing("Enviando datos");
    }

    return (<>
        <Stack spacing={2} style={{padding: "1rem"}}>
            {processingData && 
            <div style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center"}}>
                <ProcessingComponent processingText={titleProcessing}/>
            </div>
            }
            {index.current > 0 &&
                <div style={{ width: "100%", }}>
                    <IconButton onClick={() => { 
                        index.current = index.current - 1; 
                        if (errorType == api.ERROR.PIPELINE || errorType == api.ERROR.PIPELINE_STAGES) {
                            index.current = 0
                        }
                        processError(validateOptions); 
                        }} style={{ color: "white" }}>
                        <ArrowBackIcon style={{ fontSize: "35px" }} />
                    </IconButton>
                </div>

            }
            <Fade in={!processingData}>
                <Grid container style={{ flexDirection: 'column', alignItems: 'center', color: "white", marginTop: 0 }}>
                    <Stack spacing={2} alignItems="center" style={{ padding: "1rem", width: "100%" }}>

                        <Typography variant="h5" gutterBottom align="center">{errorTitle}</Typography>

                        {errorType == api.ERROR.PIPELINE && json.current.deal && Object.keys(json.current.deal).length > 0 &&
                            <Pipeline
                                dealPipeline={json.current.deal.pipeline || {}}
                                setNextDisabled={setNextDisabled}
                                loaded={() => { setLoading(false) }}
                                selectPipeline={(pipeline, justOne) => {
                                    json.current.deal.pipeline = pipeline
                                    if (justOne) {
                                        setLoading(true)
                                        index.current = index.current + 1
                                        processError(validateOptions)
                                    }
                                }
                                } />
                        }

                        {errorType == api.ERROR.PIPELINE_STAGES && json.current.deal && Object.keys(json.current.deal).length > 0 &&
                            <PipelineStages
                                stagePipeline={json.current.deal.stage || {}}
                                stages={json.current.deal.pipeline.stages}
                                loaded={() => setLoading(false)}
                                setNextDisabled={setNextDisabled}
                                selectStage={(stage, justOne) => {
                                    json.current.deal.stage = stage
                                    if (justOne) {
                                        setLoading(true)
                                        index.current = index.current + 1
                                        processError(validateOptions)
                                    }
                                }
                                } />
                        }
                        {errorType == api.ERROR.COMPANY && json.current.company && Object.keys(json.current.company).length > 0 &&
                            <Company
                                loaded={() => { setLoading(false); setNextDisabled(false) }}
                                company={json.current.company || {}} selectCompany={company => json.current.company = company} setCompany={company => json.current.company = company} />
                        }

                        {errorType == api.ERROR.DEAL && json.current.deal && Object.keys(json.current.deal).length > 0 &&
                            <Deal
                                loaded={() => { setLoading(false); setNextDisabled(false) }}
                                deal={json.current.deal || {}} createDeal={deal => json.current.deal = deal} />
                        }

                        {errorType == api.ERROR.TASK && json.current.task && Object.keys(json.current.task).length > 0 &&
                            <Task
                                loaded={() => { setLoading(false); setNextDisabled(false) }}
                                task={json.current.task || {}} setTask={task => json.current.task = task} />
                        }

                        {errorType == api.ERROR.MEETING && json.current.meeting && Object.keys(json.current.meeting).length > 1 &&
                            <Meeting
                                loaded={() => { setLoading(false); setNextDisabled(false) }}
                                showGoogleMeet={user.gmail ?? false}
                                meeting={json.current.meeting || {}} createMeeting={meeting => json.current.meeting = meeting} />
                        }

                        {errorType == api.ERROR.CONTACT && json.current.contact && Object.keys(json.current.contact).length > 0 &&
                            <Contact
                                loaded={() => { setLoading(false); setNextDisabled(false) }}
                                contact={json.current.contact || {}} userAlreadyExists={userAlreadyExists} 
                                setContact={contact => {
                                    json.current.contact = contact
                                    if (validateOptions.includes({type: api.ERROR.COMPANY, dataProcessed: false}) && json.current.company == undefined && contact.company != undefined) {
                                        json.current.company = contact.company
                                        json.current.company.options = [contact.company]
                                    }
                                }}
                            />
                        }

                        {errorType == api.ERROR.MAIL && json.current.mail && json.current.mail.message &&
                            <Mail
                                loaded={() => { setLoading(false); setNextDisabled(false) }}
                                mail={json.current.mail || {}} setMail={mail => json.current.mail = mail} />
                        }

                        {errorType == api.ERROR.NOTE && json.current.note && Object.keys(json.current.note).length > 1 &&
                            <Note
                                loaded={() => { setLoading(false); setNextDisabled(false) }}
                                note={json.current.note || {}} setNote={note => json.current.note = note} />
                        }

                        {errorType == api.ERROR.CALL && json.current.call && Object.keys(json.current.call).length > 0 &&
                            <Call
                                loaded={() => { setLoading(false); setNextDisabled(false) }}
                                call={json.current.call || {}} createCall={call => json.current.call = call} />
                        }

                        <Stack direction="column" spacing={2} style={{ width: "100%" }}>
                            <Box sx={{ m: 1, position: 'relative' }}>
                                <Button disabled={loading || nextDisabled} fullWidth variant="contained" color="primary" onClick={() => {
                                    setLoading(true)
                                    setNextDisabled(true)
                                    if (errorType == api.ERROR.CONTACT) {
                                        createContact()
                                    } else if (errorType == api.ERROR.COMPANY) {
                                        createCompany()
                                    } else if (index.current + 1 < validateOptions.length) {
                                        index.current = index.current + 1
                                        processError(validateOptions)
                                    } else {
                                        finishFlow();
                                    }
                                }}>
                                    {index.current < validateOptions.length - 1 && t('next')}
                                    {index.current == validateOptions.length - 1 && t('finish')}
                                    {loading && <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "white",
                                            position: 'absolute',
                                            top: '50%',
                                            right: '5%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                    }
                                </Button>
                            </Box>
                            <Button variant="outlined" color="error" onClick={() => { setOpen(true) }}>{t('cancel')}</Button>

                        </Stack>


                    </Stack>
                </Grid>
            </Fade>

        </Stack>
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
            <DialogContent>
                {t('cancelConfirmation')}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">{t('close')}</Button>
                <Button color="default" onClick={() => {
                    disconnect();
                    setHide(false)
                    navigate(routeBack);
                }}>{t('ok')}</Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={error}
            disableEscapeKeyDown={true}
            onClose={() => {
                setError(false)
                disconnect();
                setHide(false)
                navigate(routeBack)
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t('errorProcessingRequest')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('errorProcessingRequestDescription')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    disconnect();
                    setHide(false)
                    navigate(routeBack);
                }}>{t('ok')}</Button>
            </DialogActions>
        </Dialog>
    </>

    );
}

export default Validate;
