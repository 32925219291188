import { Checkbox, FormControlLabel, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import ContactAutocomplete from './ContactAutocomplete';
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DateTimePicker } from '@mui/x-date-pickers';
dayjs.extend(utc);
dayjs.extend(timezone);


const Meeting = ({ meeting, createMeeting, loaded, showGoogleMeet }) => {
    const { t } = useTranslation();
    const [newMeeting, setNewMeeting] = React.useState({
        title: meeting.title || '',
        startDate: meeting.startDate || 'Z',
        endTime: meeting.endTime || 'Z',
        location: meeting.location || '',
        reminder: meeting.reminder || '',
        description: meeting.description || '',
        internalNote: meeting.internalNote || '',
        attendees: meeting.attendees || [{}],
        options: meeting.options || [],
        createGoogleMeet: meeting.createGoogleMeet || false
    });
    const createMeetingData = (prevMeeting, updates) => ({
        ...prevMeeting,
        ...updates,
        fixed: true
    });
    useEffect(() => {
        loaded();
        if (newMeeting.attendees && !newMeeting.options) {
            setNewMeeting(prev => ({
                ...prev,
                options: [...prev.attendees]
            }));
        }
    }, [meeting]);

    const handleInputChange = (field) => (event) => {
        const updatedMeeting = createMeetingData(newMeeting, {
            [field]: event.target.value
        });
        setNewMeeting(updatedMeeting);
        createMeeting(updatedMeeting);
    };

    const handleInputChangeDate = (field) => (value) => {
        const updatedMeeting = createMeetingData(newMeeting, {
            [field]: value.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        });
        setNewMeeting(updatedMeeting);
        createMeeting(updatedMeeting);
    };

    const handleAddAttendee = () => {
        const updatedAttendees = [...(meeting.attendees || []), {}];
        const updatedMeeting = createMeetingData(newMeeting, {
            attendees: updatedAttendees
        });
        setNewMeeting(updatedMeeting);
        createMeeting(updatedMeeting);
    };

    const handleRemoveAttendee = (index) => {
        const updatedAttendees = [...meeting.attendees];
        updatedAttendees.splice(index, 1);
        const updatedMeeting = createMeetingData(newMeeting, {
            attendees: updatedAttendees
        });
        setNewMeeting(updatedMeeting);
        createMeeting(updatedMeeting);
    };

    return (
        <Stack spacing={2} fullWidth style={{ width: "100%" }}>
            <TextField
                fullWidth
                id="meetingTitle"
                label={t('meetingTitle')}
                multiline
                variant="standard"
                defaultValue={newMeeting.title}
                onChange={handleInputChange('title')}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    label={t('meetingStartDate')}
                    value={dayjs(newMeeting.startDate)}
                    onChange={handleInputChangeDate('startDate')}
                />
            </LocalizationProvider>
            
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    label={t('meetingEndDate')}
                    value={dayjs(newMeeting.endTime)}
                    onChange={handleInputChangeDate('endTime')}
                />
            </LocalizationProvider>

                <TextField
                fullWidth
                id="meetingLocation"
                label={t('meetingLocation')}
                variant="standard"
                defaultValue={newMeeting.location}
                onChange={handleInputChange('location')}
            />

            {showGoogleMeet && (
                <>
                <FormControlLabel
                control={
                    <Checkbox
                        checked={newMeeting.createMeeting}
                        onChange={(event) => {
                            const updatedMeeting = createMeetingData(newMeeting, {
                                createMeeting: event.target.checked
                            });
                            setNewMeeting(updatedMeeting);
                            createMeeting(updatedMeeting);
                        }}
                    />
                }
                label={t('createMeeting')}
                />
                {newMeeting.createMeeting && (
                <FormControlLabel
                control={
                    <Checkbox
                        checked={newMeeting.createGoogleMeet}
                        onChange={(event) => {
                            const updatedMeeting = createMeetingData(newMeeting, {
                                createGoogleMeet: event.target.checked
                            });
                            setNewMeeting(updatedMeeting);
                            createMeeting(updatedMeeting);
                        }}
                    />
                    }
                    label={t('createGoogleMeet')}
                />
                )}
                </>
            )}

            <TextField
                fullWidth
                id="meetingReminder"
                label={t('meetingReminder')}
                multiline
                variant="standard"
                defaultValue={newMeeting.reminder}
                onChange={handleInputChange('reminder')}
            />

            <TextField
                fullWidth
                id="meetingDescription"
                label={t('meetingDescription')}
                multiline
                variant="standard"
                defaultValue={newMeeting.description}
                onChange={handleInputChange('description')}
            />

            <TextField
                fullWidth
                id="meetingInternalNote"
                label={t('meetingInternalNote')}
                multiline
                variant="standard"
                defaultValue={newMeeting.internalNote}
                onChange={handleInputChange('internalNote')}
            />

            <Stack spacing={2} fullWidth style={{ width: "100%", alignItems: "center" }} direction="row">
                <Typography variant="h6">{t('attendees')}</Typography>
                <AddCircleIcon onClick={handleAddAttendee} />
            </Stack>

            {newMeeting.attendees?.map((attendee, index) => (
                <Stack key={index} spacing={2} fullWidth style={{ width: "100%", alignItems: "center" }} direction="row">
                    <ContactAutocomplete
                        opts={newMeeting.options}
                        contact={attendee}
                        setContact={(contact) => {
                            const updatedAttendees = [...newMeeting.attendees];
                            updatedAttendees[index] = contact;
                            const updatedMeeting = createMeetingData(newMeeting, {
                                attendees: updatedAttendees
                            });
                            setNewMeeting(updatedMeeting);
                            createMeeting(updatedMeeting);
                        }}
                    />
                    <RemoveCircleIcon onClick={() => handleRemoveAttendee(index)} />
                </Stack>
            ))}
        </Stack>
    );
}

export default Meeting;