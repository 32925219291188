import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      es: {
        translation: {
          noCreditsLeft: "No tienes créditos disponibles",
          login: "Iniciar sesión",
          sendingData: "Enviando datos",
          notObjectProcessed: "No se han podido procesar los siguientes objetos:\n",
          company: "Compañía",
          contact: "Contacto",
          deal: "Negocio",
          task: "Tarea",
          meeting: "Reunión",
          mail: "Correo",
          contacts: "Contactos",
          note: "Nota",
          call: "Llamada",
          pipeline: "Pipeline",
          pipelineStages: "Etapas",
          other: "Otro",
          selectPipeline: "Seleccione un pipeline",
          selectPipelineStage: "Seleccione una etapa",
          selectCompany: "Valide los datos de la compañía",
          selectContact: "Valide los datos del contacto",
          selectTask: "Valide los datos de la tarea",
          selectMeeting: "Valide los datos de la reunión",
          selectMail: "Valide los datos del correo",
          selectNote: "Valide los datos de la nota",
          selectCall: "Valide los datos de la llamada",
          selectDeal: "Valide los datos del negocio",
          next: "Siguiente",
          finish: "Finalizar",
          cancel: "Cancelar",
          cancelConfirmation: "¿Estás seguro de que quieres cancelar?",
          close: "Cerrar",
          ok: "Ok",
          errorProcessingRequest: "Error al procesar la solicitud",
          errorProcessingRequestDescription: "Inténtelo de nuevo.",
          processingAudio: "Procesando audio",
          minutesSaved: "Has ahorrado",
          minutes: "minutos",
          savedIn: "en",
          audioSentCorrectly: "Audio enviado correctamente",
          retry: "Reintentar",
          dateTime: "Fecha y hora",
          duration: "Duración",
          disposition: "Resultado",
          busy: "Ocupado",
          connected: "Conectado",
          noResponse: "No respondió",
          wrongNumber: "Número incorrecto",
          voiceMessage: "Dejó mensaje de voz",
          liveMessage: "Dejó mensaje en vivo",
          direction: "Dirección",
          inbound: "ENTRANTE",
          outbound: "SALIENTE",
          title: "Título",
          audioText: "Pulsa para grabar",
          description: "Descripción",
          associations: "Asociaciones",
          companies: "Empresas",
          deals: "Negocios",
          companyName: "Nombre de la compañía",
          companyDomain: "Dominio de la compañía",
          selectCompany: "Selecciona una compañía",
          createCompany: "Crear compañía",
          errorSendingAudio: "Error al enviar el audio",
          createCompanyDescription: "Crea una nueva compañía",
          userAlreadyExists: "El usuario ya existe, por favor cambie a seleccionar usuario",
          name: "Nombre",
          lastName: "Apellidos",
          email: "Email",
          phoneNumber: "Teléfono",
          selectContact: "Selecciona un contacto",
          createContact: "Crear contacto",
          dealName: "Nombre del negocio",
          dealValue: "Valor del negocio",
          dealClose: "Fecha de cierre del negocio",
          selectDeal: "Selecciona un negocio",
          recipients: "Destinatarios",
          subject: "Asunto",
          message: "Mensaje",
          meetingTitle: "Título de la reunión",
          meetingStartDate: "Fecha de inicio",
          meetingEndDate: "Fecha de finalización",
          meetingLocation: "Lugar",
          meetingReminder: "Recordatorio",
          meetingDescription: "Descripción",
          meetingInternalNote: "Nota interna",
          low: "Baja",
          medium: "Media",
          high: "Alta",
          dueDate: "Fecha de vencimiento (en días)",
          hsTaskType: "Tipo de tarea",
          call: "Llamada",
          email: "Correo electrónico",
          todo: "Tarea",
          crmUpdated: "CRM actualizado correctamente",
          domain: "Dominio",
          phone: "Teléfono",
          address: "Dirección",
          audioCompanyText: "Pulsa para grabar acciones sobre esta compañía",
          errorMovingDeal: "Error al mover el negocio. Por favor, inténtalo de nuevo más tarde.",
          selectStage: "Selecciona a que etapa del pipeline quieres pasar este negocio.",
          moveDeal: "Mover a otra etapa",
          save: "Guardar",
          stage: "Etapa",
          amount: "Importe",
          closedate: "Fecha de cierre",
          createdate: "Fecha de creación",
          audioContactText: "Pulsa para grabar acciones sobre este contacto",
          record: "Grabar",
          account: "Cuenta",
          recording: "Grabando audio...",
          paused: "Pausado",
          microphone: "Micrófono",
          recordingCancelled: "Grabación cancelada",
          search: "Buscar",
          min: "min",
          sortBy: "Ordenar por",
          newest: "Más nuevo",
          oldest: "Más antiguo",
          disconnectGmail: "Desconectar Gmail",
          logout: "Cerrar sesión",
          connectGmail: "Conectar Gmail",
          gmailConnected: "Gmail conectado correctamente",
          gmailDisconnected: "Gmail desconectado correctamente",
          attendees: "Asistentes",
          properties: "Propiedades",
          select_properties: "Seleccione propiedades",
          propertyToUpdate: "Propiedad a actualizar",
          selectProperty: "Seleccione propiedades",
          updateProperties: "Actualizar propiedades",
          propertiesUpdated: "Propiedades actualizadas correctamente",
          propertiesUpdatedError: "Error al actualizar las propiedades",
          createGoogleMeet: "Enviar enlace de Google Meet",
          createMeeting: "Enviar convocatoria de reunión al cliente",
        }
      },
      pt: {
        translation: {
          noCreditsLeft: "Sem créditos disponíveis",
          login: "Iniciar sessão",
          sendingData: "Enviando dados",
          notObjectProcessed: "Não foi possível processar os seguintes objetos:\n",
          audioText: "Pulsa para gravar",
          company: "Empresa",
          contact: "Contato",
          contacts: "Contactos",
          deal: "Negócio",
          task: "Tarefa",
          meeting: "Reunião",
          mail: "E-mail",
          note: "Nota",
          call: "Chamada",
          pipeline: "Pipeline",
          pipelineStages: "Etapas",
          other: "Outro",
          selectPipeline: "Selecione um pipeline",
          selectPipelineStage: "Selecione uma etapa",
          selectCompany: "Valide os dados da empresa",
          selectContact: "Valide os dados do contato",
          selectTask: "Valide os dados da tarefa",
          selectMeeting: "Valide os dados da reunião",
          selectMail: "Valide os dados do e-mail",
          selectNote: "Valide os dados da nota",
          selectCall: "Valide os dados da chamada",
          selectDeal: "Valide os dados do negócio",
          next: "Próximo",
          finish: "Finalizar",
          cancel: "Cancelar",
          cancelConfirmation: "Tem certeza que deseja cancelar?",
          close: "Fechar",
          ok: "Ok",
          errorProcessingRequest: "Erro ao processar a solicitação",
          errorProcessingRequestDescription: "Tente novamente.",
          errorSendingAudio: "Erro ao enviar o áudio",
          processingAudio: "Processando áudio",
          minutesSaved: "Você economizou",
          minutes: "minutos",
          min: "min",
          savedIn: "em",
          audioSentCorrectly: "Áudio enviado corretamente",
          retry: "Tentar novamente",
          dateTime: "Data e hora",
          duration: "Duração",
          disposition: "Resultado",
          busy: "Ocupado",
          connected: "Conectado",
          noResponse: "Sem resposta",
          wrongNumber: "Número incorreto",
          voiceMessage: "Deixou mensagem de voz",
          liveMessage: "Deixou mensagem ao vivo",
          direction: "Direção",
          inbound: "ENTRADA",
          outbound: "SAÍDA",
          title: "Título",
          description: "Descrição",
          associations: "Associações",
          companies: "Empresas",
          deals: "Negócios",
          companyName: "Nome da empresa",
          companyDomain: "Domínio da empresa",
          selectCompany: "Selecione uma empresa",
          createCompany: "Criar empresa",
          createCompanyDescription: "Crie uma nova empresa",
          userAlreadyExists: "O usuário já existe, por favor mude para selecionar usuário",
          name: "Nome",
          lastName: "Sobrenome",
          email: "E-mail",
          phoneNumber: "Telefone",
          selectContact: "Selecione um contato",
          createContact: "Criar contato",
          dealName: "Nome do negócio",
          dealValue: "Valor do negócio",
          dealClose: "Data de fechamento do negócio",
          selectDeal: "Selecione um negócio",
          recipients: "Destinatários",
          subject: "Assunto",
          message: "Mensagem",
          meetingTitle: "Título da reunião",
          meetingStartDate: "Data de início",
          meetingEndDate: "Data de término",
          meetingLocation: "Local",
          meetingReminder: "Lembrete",
          meetingDescription: "Descrição",
          meetingInternalNote: "Nota interna",
          low: "Baixa",
          medium: "Média",
          high: "Alta",
          dueDate: "Data de vencimento (em dias)",
          hsTaskType: "Tipo de tarefa",
          call: "Chamada",
          email: "E-mail",
          todo: "Tarefa",
          crmUpdated: "CRM atualizado corretamente",
          domain: "Domínio",
          phone: "Telefone",
          address: "Endereço",
          audioCompanyText: "Clique para gravar ações sobre esta empresa",
          errorMovingDeal: "Erro ao mover o negócio. Por favor, tente novamente mais tarde.",
          selectStage: "Selecione para qual etapa do pipeline você quer mover este negócio.",
          moveDeal: "Mover para outra etapa",
          save: "Salvar",
          stage: "Etapa",
          amount: "Valor",
          closedate: "Data de fechamento",
          createdate: "Data de criação",
          audioContactText: "Clique para gravar ações sobre este contato",
          record: "Gravar",
          account: "Conta",
          recording: "Gravando áudio...",
          paused: "Pausado",
          microphone: "Microfone",
          recordingCancelled: "Gravação cancelada",
          search: "Buscar",
          sortBy: "Ordenar por",
          newest: "Mais recente",
          oldest: "Mais antigo",
          disconnectGmail: "Desconectar Gmail",
          logout: "Sair",
          connectGmail: "Conectar Gmail",
          gmailConnected: "Gmail conectado corretamente",
          gmailDisconnected: "Gmail desconectado corretamente",
          attendees: "Participantes",
          properties: "Propriedades",
          select_properties: "Selecione propriedades",
          propertyToUpdate: "Propriedade a atualizar",
          selectProperty: "Selecione propriedades",
          updateProperties: "Atualizar propriedades",
          propertiesUpdated: "Propriedades atualizadas corretamente",
          propertiesUpdatedError: "Erro ao atualizar as propriedades",
          createGoogleMeet: "Enviar link do Google Meet",
          createMeeting: "Enviar convite de reunião ao cliente",
        }
      }
    }
  });

export function getLanguage() {
  return i18n.language.split('-')[0]; // Returns just the ISO-639-1 part (e.g. 'es' from 'es-ES')
}

export default i18n;