import SortOutlined from "@mui/icons-material/SortOutlined";
import { Divider, IconButton, InputAdornment, Popper, Stack, TextField, Typography } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
const SearchComponent = ({createdate, handleSortChange, handleSearchChange}) => {
    const { t } = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const searchChange = (event) => {
        setSearchTerm(event.target.value);
        handleSearchChange(event.target.value);
    }

    return <>
    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} style={{width: '100%'}}>
                <TextField
                    fullWidth
                    placeholder={t('search')}
                    value={searchTerm}
                    onChange={searchChange}
                    slotProps={{
                        input: {
                            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                            endAdornment: <InputAdornment position="end">{searchTerm && searchTerm.length > 0 && <ClearIcon onClick={() => {
                                setSearchTerm('');
                                searchChange({ target: { value: '' } });
                            }} />} </InputAdornment>,
                        }
                    }}
                />
                <IconButton aria-label="delete" style={{ backgroundColor: drawerOpen ? "#FFFFFF20" : "transparent" }} onClick={(event) => {
                    setDrawerOpen(!drawerOpen);
                    setAnchorEl(event.currentTarget);
                }}>
                    <SortOutlined />
                </IconButton>


            <Popper
                placement="bottom-end"
                open={drawerOpen}
                disablePortal={false}
                anchorEl={anchorEl}
                modifiers={[
                    {
                        name: 'flip',
                        enabled: true,
                        options: {
                            altBoundary: true,
                            rootBoundary: 'document',
                            padding: 8,
                        },
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            altAxis: true,
                            altBoundary: true,
                            tether: true,
                            rootBoundary: 'document',
                            padding: 8,
                        },
                    },
                    {
                        name: 'arrow',
                        enabled: false,
                        
                    },
                ]}
            >
                <Stack elevation={3} direction="column" spacing={2} style={{ backgroundColor: 'white', padding: '20px', borderRadius: '20px', marginTop: '10px', width: '280px' }}>
                    <Typography style={{ color: 'gray', fontSize: '16px',  }}>{t('sortBy')}</Typography>
                    <Stack style={{ marginLeft: '20px' }} onClick={() => {
                        handleSortChange(searchTerm, "DESCENDING");
                    }} direction="row" justifyContent="start" alignItems="start" spacing={1}>
                        {createdate == "DESCENDING" && <CheckIcon />}
                        <Typography variant="body1">{t('newest')}</Typography>
                    </Stack>
                    <Divider style={{ backgroundColor: '#dcdcdc' }} orientation="horizontal" />
                    <Stack style={{ marginLeft: '20px' }} onClick={() => {
                        handleSortChange(searchTerm, "ASCENDING");
                    }} direction="row" justifyContent="start" alignItems="start" spacing={1}>
                        {createdate == "ASCENDING" && <CheckIcon />}
                        <Typography variant="body1">{t('oldest')}</Typography>
                    </Stack>
                </Stack>
            </Popper>
            </Stack>
    </>
}

export default SearchComponent;